<template>
  <div>
    <div id="core"></div>
    <div class="bottom_bar">
      <div class="lv_flex">
        <div class="lv_flex_1" @click="reflash">刷新</div>
        <div class="lv_flex_1" @click="show = true">信息</div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" round>
      <div class="share_pop">
        <van-field v-model="form.name" label="作品名称" readonly />
        <van-field v-model="form.userName" label="创作作者" readonly />
        <van-field
          v-if="form.flowers"
          v-model="form.flowers"
          label="使用花材"
          autosize
          readonly
          type="textarea"
        />
        <van-field
          v-if="form.container"
          v-model="form.container"
          label="使用器皿"
          readonly
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Editor } from "../../../3D-editor/index";
import { login } from "@/plugins/utils.js";
let editor;
export default {
  mounted() {
    login(() => {
      editor = new Editor({
        dom: "core",
        plat: "mobile",
        readOnly: true,
      });

      if (this.$store.state.routeParams.productId) {
        this.getData(this.$store.state.routeParams.productId);
      }
    }, this);
  },
  methods: {
    reflash() {
      editor.setAngle({
        x: this.lateralAxis,
        y: this.verticalAxis,
      });
    },
    getData(id) {
      this.$ajax
        .get("/product/getById", {
          params: {
            id,
            type: this.$route.query.type || 2,
          },
        })
        .then((res) => {
          document.title = res.data.name;
          let dataJson = JSON.parse(res.data.dataJson);
          this.lateralAxis = parseInt(dataJson.angle.x);
          this.verticalAxis = parseInt(dataJson.angle.y);
          this.form = {
            name: res.data.name,
            userName: res.data.userName,
            flowers: res.data.useMaterials
              .map((item) => {
                return `${item.materialName}（${item.count}朵）`;
              })
              .join("\n"),
            container: dataJson.objects.container
              ? dataJson.objects.container.resourceName
              : "",
          };

          editor.load(dataJson);
          this.reflash();
        });
    },
  },
  data() {
    return {
      lateralAxis: 0,
      verticalAxis: 0,
      show: false,
      id: "",
      form: {
        name: "",
        userName: "",
        flowers: "",
        container: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.share_pop {
  padding: rem(30) 0;
}
#core {
  width: 100vw;
  height: 100vh;
}
.bottom_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
.lv_flex {
  padding: rem(12) rem(20);
  > div {
    line-height: rem(80);
    text-align: center;
    background: #6e9f21;
    color: #fff;
    font-size: rem(28);

    border-radius: rem(6);
    + div {
      margin-left: rem(20);
    }
  }
}
</style>