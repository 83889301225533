function renderModel(env) {
    if(env.three.status === 'model'){
        return;
    }
    for (let flowerObject of env.objects.flowers) {
        flowerObject.clearReal();
    }
    env.three.status = 'model'
}

export default renderModel;