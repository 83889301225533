/*
 * @Author: your name
 * @Date: 2022-01-26 09:44:26
 * @LastEditTime: 2022-02-10 17:42:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \front-wx\src\ajax\ajax.js
 */
import Vue from "vue";
import axios from "axios";
import { Toast } from 'vant';
let baseURL = 'https://sfhx-api.lvshuhualv.com/';
if (process.env.NODE_ENV === 'development') {
  baseURL = 'https://sfhx-api.lvshuhualv.com/';
}
let ajax = axios.create({
  baseURL,
  withCredentials: true
});
let ajaxNum = 0;
ajax.interceptors.request.use((config) => {
  if (ajaxNum === 0) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
  }
  ajaxNum++;
  return config
})

// 添加响应拦截器
ajax.interceptors.response.use(
  (res) => {
    ajaxNum--;
    if (ajaxNum === 0) {
      Toast.clear();
    }
    // 对响应数据做点什么
    const code = res.data.code || 200;
    if (code === 401) {
    } else if (code !== 200) {
      if (code === 403) {
        return Promise.reject(res.data);
      }
      Toast(res.data.msg);
      return Promise.reject(res.data);
    }
    return res.data;
  },
  (error) => {
  }
);

Vue.prototype.$ajax = ajax;
Vue.prototype.$axios = axios;