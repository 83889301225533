import axios from 'axios';
let baseURL = 'https://sfhx-api.lvshuhualv.com/';

if (process.env.NODE_ENV !== 'development') {
    baseURL = 'https://sfhx-api.lvshuhualv.com/';
}

let isDev = process.env.NODE_ENV === 'development',
    ajax = axios.create({
        baseURL,
        withCredentials: true
    });

ajax.interceptors.request.use(function (config) {
    return config;
});
ajax.interceptors.response.use(
    function (res) {
        return res.data;
    },
    function (error) {
        return Promise.reject('网络错误');
    }
);

export default ajax;
