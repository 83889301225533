<template>
    <div>
        <div id="core"></div>
        <van-dialog
            v-model="nameTip"
            title="作品名称"
            @confirm="save"
            :close-on-click-overlay="true"
        >
            <van-field v-model="name" label="" placeholder="请填写作品名称" />
        </van-dialog>
    </div>
</template>

<script>
import { Editor } from "../../../../3D-editor/index";
import { base64ToFile, uploadImage } from "@/plugins/utils.js";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
let editor;
export default {
    data() {
        return {
            id: null,
            name: null,
            ossData: null,
            describe: null,
            nameTip: false,

            autoSaveTimer: null,
        };
    },
    destroyed() {
        this.autoSaveTimer && clearInterval(this.autoSaveTimer);
    },
    mounted() {
        editor = new Editor({
            dom: "core",
            plat: "mobile",
            readOnly: this.$store.state.routeParams.mode !== "edit",
        });
        this.getOSSKey();
        //添加bus线事件
        this.addBusEvent();
        //添加编辑器事件
        this.addEditorEvent();
        if (this.$store.state.routeParams.productId) {
            this.id = this.$store.state.routeParams.productId;
            this.getData(this.id);
        }

        this.$nextTick(() => {
            const { productId, type, mode } = this.$route.query;
            console.log();
            if ((mode === "edit" || !mode) && !productId) {
                let dataJson = localStorage.getItem(type + "JsonData");
                if (dataJson) {
                    dataJson = JSON.parse(dataJson);

                    this.$bus.$emit("setDefaultAngle", dataJson.angle);
                    editor.load(dataJson);
                }

                this.autoSaveCG();
            }
        });
    },
    methods: {
        autoSaveCG() {
            this.autoSaveTimer && clearInterval(this.autoSaveTimer);

            this.autoSaveTimer = setInterval(async () => {
                let saveData = await editor.save();

                localStorage.setItem(
                    this.$route.query.type + "JsonData",
                    JSON.stringify(saveData.jsonData)
                );
            }, 5000);
        },
        getOSSKey() {
            return new Promise(resolve => {
                this.$ajax
                    .post("/user/oss/getKey?pathName=common")
                    .then(async res => {
                        this.ossData = res.data;
                        resolve(res.data);
                    });
            });
        },
        getData(id) {
            this.$ajax
                .get("/product/getById", {
                    params: {
                        id,
                        type: 1,
                    },
                })
                .then(res => {
                    this.name = res.data.name;
                    this.describe = res.data.describe;
                    let dataJson = JSON.parse(res.data.dataJson);
                    this.$bus.$emit("setDefaultAngle", dataJson.angle);
                    editor.load(dataJson);
                });
        },
        addBusEvent() {
            //缩放
            this.$bus.$on("set-zoom", val => {
                editor.setZoom(val);
            });
            //添加
            this.$bus.$on("addItem", id => {
                editor.addItem(id);
            });
            //弯曲
            this.$bus.$on("setBindingY", data => {
                editor.setBindingY(data);
            });
            //大小
            this.$bus.$on("setSize", data => {
                editor.setSize(data);
            });
            //旋转
            this.$bus.$on("rotate", p => {
                let r = editor.rotate(p);
                this.$bus.$emit("rotate-result", r);
            });
            //移除
            this.$bus.$on("remove", id => {
                editor.remove(id);
            });

            //清除
            this.$bus.$on("clear", type => {
                editor.clear(type);
            });

            //复制
            this.$bus.$on("copy", id => {
                editor.copy(id);
            });

            //替换
            this.$bus.$on("replace", params => {
                editor.replace(params);
            });

            //触摸点
            this.$bus.$on("setTouchHelper", val => {
                editor.setTouchHelper(val);
            });

            //插法
            this.$bus.$on("setBindingType", type => {
                editor.setBindingType(type);
            });

            //画布锁定
            this.$bus.$on("canvasLock", val => {
                editor.canvasLock(val);
            });

            //角度
            this.$bus.$on("setAngle", val => {
                editor.setAngle(val);
            });

            //后退
            this.$bus.$on("revoke", () => {
                editor.revoke();
            });

            //前进
            this.$bus.$on("redo", () => {
                editor.redo();
            });

            //插法
            this.$bus.$on("setContainerSize", val => {
                editor.setContainerSize(val);
            });

            //控制剑山
            this.$bus.$on("showJsContainer", val => {
                editor.showJsContainer(val);
            });

            //保存
            this.$bus.$on("save", (type, form) => {
                if (type === "practice") {
                    this.save();
                } else {
                    this.workSave(form);
                }
            });

            //渲染
            this.$bus.$on("renderRealScene", () => {
                editor.renderRealScene();
            });
        },
        addEditorEvent() {
            editor.addEventListener("contain-change", item => {
                Toast("操作成功");
            });

            editor.addEventListener("flower-choose", val => {
                this.$bus.$emit("flower-choose", val);
            });

            editor.addEventListener("camera-change", val => {
                this.$bus.$emit("camera-change", val);
            });

            editor.addEventListener("step", val => {
                this.$bus.$emit("step-change", val);
            });

            editor.addEventListener("container-add", val => {
                this.$bus.$emit("container-add", val);
            });

            editor.addEventListener("error", val => {
                Toast(val.msg);
            });
        },
        async workSave(form) {
            let saveData = await editor.save({
                angles: [
                    {
                        x: 90,
                        y: 0,
                    },
                    {
                        x: 90,
                        y: 90,
                    },
                    {
                        x: 90,
                        y: 180,
                    },
                    {
                        x: 90,
                        y: 270,
                    },
                    {
                        x: 0,
                        y: 0,
                    },
                ],
            });

            const useMaterials = saveData.jsonData.objects.flowers.reduce(
                (obj, item) => {
                    let find = obj.find(i => i.materialId === item.resourceId);
                    let _d = {
                        materialId: item.resourceId,
                        materialName: item.resourceName,
                        count: 1,
                    };
                    find ? find.count++ : obj.push(_d);
                    return obj;
                },
                []
            );

            let uploadPromises = [];

            await this.getOSSKey();

            for (let base64 of saveData.base64) {
                uploadPromises.push(
                    uploadImage(
                        {
                            file: base64ToFile(
                                base64,
                                new Date().getTime() + ".png"
                            ),
                        },
                        this.ossData
                    )
                );
            }
            Promise.all(uploadPromises).then(fileAddrs => {
                this.$ajax
                    .post("/product/save", {
                        id: null,
                        dataJson: JSON.stringify(saveData.jsonData),
                        source: 3,
                        name: form.name,
                        coverImage: fileAddrs[1].addr,
                        useMaterials,
                        describe: null,
                    })
                    .then(res => {
                        const answerTime = parseInt(
                            (new Date().getTime() -
                                sessionStorage.getItem("startTime")) /
                                (1000 * 60)
                        );
                        const type = this.$store.state.routeParams.type;
                        let params = {
                            id: this.$store.state.routeParams.id,
                            productId: res.data,
                            answerTime: answerTime || 1,
                        };
                        params[type + "Images"] = fileAddrs.map(item => {
                            return item.addr;
                        });
                        params[type + "Desc"] = form.desc;
                        this.$ajax
                            .post(`/${type}/student/submit`, params)
                            .then(res => {
                                localStorage.removeItem(
                                    this.$route.query.type + "JsonData"
                                );

                                Toast("提交成功");
                                editor.clear();
                                setTimeout(() => {
                                    wx.miniProgram.navigateBack({});
                                }, 500);
                            });
                    });
            });
        },
        async save() {
            this.nameTip = false;
            let saveData = await editor.save();
            if (
                !saveData.jsonData.objects.flowers.length &&
                !saveData.jsonData.objects.container
            ) {
                Toast("请先编辑后再保存");
                return;
            }
            if (!this.id && !this.name) {
                Toast("请输入作品名称");
                this.nameTip = true;
                return;
            }

            const useMaterials = saveData.jsonData.objects.flowers.reduce(
                (obj, item) => {
                    let find = obj.find(i => i.materialId === item.resourceId);
                    let _d = {
                        materialId: item.resourceId,
                        materialName: item.resourceName,
                        count: 1,
                    };
                    find ? find.count++ : obj.push(_d);
                    return obj;
                },
                []
            );
            let file = base64ToFile(
                saveData.base64,
                new Date().getTime() + ".png"
            );

            await this.getOSSKey();

            uploadImage(
                {
                    file,
                },
                this.ossData
            ).then(res => {
                this.$ajax
                    .post("/product/save", {
                        id: this.id,
                        dataJson: JSON.stringify(saveData.jsonData),
                        source: 1,
                        name: this.name,
                        coverImage: res.addr,
                        useMaterials,
                        describe: this.describe,
                    })
                    .then(res => {
                        localStorage.removeItem(
                            this.$route.query.type + "JsonData"
                        );

                        Toast("保存成功");
                        this.id = null;
                        this.name = null;
                        editor.clear();
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
#core {
    width: 100vw;
    height: 100vh;
}
</style>
